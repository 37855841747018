* {
  font-family: 'Roboto', sans-serif;
}

.background-container {
  position: relative;
  height: 77vh;
  background-image: url('img/MachineryInventory.png');
  background-size: cover;
}

.title-container {
  padding-top: 8rem;
}

.title-container h1 {
  color: white;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  letter-spacing: 2px;
}

.title-container h2 {
  color: #dfd4b3;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  letter-spacing: 2px;
}

.main-container {
  background-color: #EFEFEF;
  padding-bottom: 1px;
}

.card-container {
  background-color: #E4DFCB;
  text-align: justify;
  border-radius: 10px;
  padding: 20px;
}

.logo-text h1 {
  font-family: 'Gill Sans', fantasy;
}

@media (max-width: 600px) {
  .card-responsive {
    width: auto !important;
    height: auto !important;
  }

  .card-img-responsive {
    height: 250px;
    width: 250px;
    align-self: center;
  }
}